body {
  color: #fff;
}

.mint-page {
  height: 100vh;
  background-image: url("./assets/img/bg.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.navb {
  padding-top: 3rem;
  display: flex;
  justify-content: space-between;
}

.logo {
  height: 60px;
}

.cusbtn {
  background-color: #2a2d36;
  color: #fff;
  /* font-size: 1rem; */
  padding: 0.5rem 1.5rem;
  /* border: 3px solid #ffd7a3; */
  /* font-weight: bold; */
  border-radius: 10px;
  letter-spacing: 3px;
}

.cusbtn:hover {
  color: #fff;
}

.mintbutton {
  background-color: #fff;
  color: #000;
  font-weight: bold;
  letter-spacing: 3px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.mint {
  margin-top: 6rem;
  background-color: #161f23c2;
  padding: 4rem;
  border-radius: 1.5rem;
}
.mih1 {
  font-weight: bold;
  font-size: 2.7rem;
  letter-spacing: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.subtitle {
  font-size: 1.3rem;
  letter-spacing: 3px;
}

.plus-img {
  height: 60px;
  cursor: pointer;
}

.minus,
.plus {
  width: 70px;
  border-radius: 50%;
  background-color: #fff;
  font-weight: bold;
  border: 3px solid #fff;
  font-size: 30px;
}

.display-number {
  color: #000;
  background-color: #fff;
  height: 70px;
  width: 70px;
  border-radius: 4px;
}
.number {
  font-weight: bold;
  font-size: 1.3rem;
}

.si {
  height: 50px;
  margin: 0 10px;
}

.footer {
  background-color: #000;
  padding: 3rem 0;
}
.ull {
  list-style: none;
}
a {
  color: #fff !important;
  text-decoration: none;
}
a:hover {
  color: #fff !important;
  text-decoration: none;
}
@media only screen and (max-width: 600px) {
  .mint-page {
    height: auto !important;
  }
  .rightlogo {
    margin-top: 5rem;
  }

  .navb {
    flex-direction: column;
    justify-content: center;
  }
  .rmt {
    margin-top: 1rem;
  }
}
